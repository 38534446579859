/* eslint-disable max-len */
import { Theme, AppImages, variantProvider } from '@/app'
import { Page, Text, View, Image, Icon } from '@/components'
import { PropsOf } from '@codeleap/common'

import { SubtitleAndDescription, LargeImage, Headlines } from '@/layout'

type AboutSectionProps = {
  wrapperProps: PropsOf<typeof View>
} & Partial<SectionsDataProps>

type SectionsDataProps = {
  id?: number
  title: string
  subtitle: string
  imageSource?: PropsOf<typeof Image>['source']
  bottomImageSource?: PropsOf<typeof Image>['source']
}

const IMAGE_SIZE = 200

const sectionsData: SectionsDataProps[] = [
  { title: 'Verification', subtitle: "Cherry makes sure it's real. Your identity - and, most importantly, their identity - is checked using Onfido, which is used by some of the world's leading apps. Onfido verifies identities, including photos, against recognized ID documents.", imageSource: AppImages.Verification },
  { title: 'Detailed Questionnaire', subtitle: 'Searching for a soulmate... or just a dinner date while you’re in the city? Fill out two private questionnaires (one about you, one about them) and Cherry filters out your frogs.', imageSource: AppImages.Questionnaire },
  { title: 'Voice and Video calling', subtitle: "Step away from emojis and start a meaningful conversation. Our voice and video functions are safe and secure, so when you're ready to chat, so are we.", imageSource: AppImages.VoiceMessages },
  { title: 'Tailor-made Matches', subtitle: "We use more factors in our questionnaire than most dating apps, and we score compatibility by interests, personality and values. So you can be confident that you'll have lots in common with your tailor-made matches.", imageSource: AppImages.Matches },
]

const AboutHereFor = ({ title, description }) => {
  return (
    <View variants={['fullWidth', 'column']}>
      <View
        variants={['fullWidth', 'alignCenter', 'gap:1', 'marginBottom:2']}
        responsiveVariants={{ small: ['center'] }}
      >
        <Icon name={'heart'} />
        <Text variants={['h3', 'black']} text={`${title}`} />
      </View>
      <Text
        variants={['black700']} text={`${description}`}
        responsiveVariants={{ small: ['textCenter'] }}
      />
    </View>
  )
}

const AboutSection = (props: AboutSectionProps) => {

  const { imageSource, bottomImageSource, wrapperProps, title, subtitle } = props

  return (
    <View variants={['fullWidth', 'gap:5', 'alignCenter']} {...wrapperProps}>
      <Image
        objectFit='contain'
        css={styles.image}
        source={imageSource}
      />
      <View
        variants={['column', 'gap:2']}
        responsiveVariants={{ small: ['center'] }}
      >
        <Text variants={['h3', 'bold', 'black700']} text={`${title}`}

        />
        <Text
          variants={['black700']}
          text={`${subtitle}`}
          responsiveVariants={{ small: ['textCenter'] }}
        />
        {!!bottomImageSource && (
          <Image
            objectFit='contain'
            css={styles.bottomImage}
            source={bottomImageSource}
          />
        )}
      </View>
    </View>
  )
}

const About:React.FC = () => {
  return (
    <Page
      center={true}
      title='About'
      variants={['column', 'fullWidth', 'justifyStart']}
      styles={{ innerWrapper: { justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: Theme.spacing.value(16) }}}
      headerProps={{ white: true }}
    >
      <View variants={['marginBottom:6']}>
        <Text variants={['h2', 'black700', 'bold']} text={'About'} />
        <Text variants={['h2', 'primary', 'marginLeft:1.5', 'bold']} text={'Cherry'} />
      </View>
      <View variants={['fullWidth', 'alignCenter']}>
        <View variants={['fullWidth', 'gap:6', 'alignCenter']} responsiveVariants={{ mid: ['column', 'center'] }}>
          <Image
            objectFit={'contain'}
            css={styles.imageAbout}
            source={AppImages.ContactBig}
            variants={['marginLeft:6']}
            responsiveVariants={{ mid: ['marginLeft:0'] }}
          />
          <View variants={['column', 'gap:6']}>
            <AboutHereFor
              title={"We're here for..."}
              description={"Helping you find the love of your life, a long-term companion or the start of something special. And giving you a fun, safe and honest environment that's been designed to make life easy, supported by a company that cares."}
            />
            <AboutHereFor
              title={"You're here for..."}
              description={"A simple way to connect. We'll find out about you, and what you're looking for so we can find genuine matches that make you smile!"}
            />
          </View>
        </View>
      </View>
      <Text
        variants={['h3', 'bold', 'black800', 'marginBottom:6']}
        responsiveVariants={{ mid: ['marginTop:6'] }}
        text={'App features'}
      />
      <View variants={['column', 'gap:8']}>
        {sectionsData.map((el, index) => (
          <AboutSection
            bottomImageSource={index === 0 && AppImages.Onfido}
            wrapperProps={{
              variants: index % 2 === 1 ? ['rowReverse', 'alignCenter', 'gap:5', 'fullWidth'] : ['fullWidth', 'gap:5', 'alignCenter'],
              responsiveVariants: { small: ['column', 'alignCenter'] },
            }}

            {...el}
          />
        ))}
      </View>
    </Page>
  )

}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    image: {
      width: IMAGE_SIZE,
      height: IMAGE_SIZE,
      // marginTop: 200,
    },
    imageAbout: {
      width: 400,
      height: 400,
    },
    bottomImage: {
      width: 137,
      height: 40,
    },
  }),
  true,
)

export default About
